.post-wrapper {
    display: flex;
  }
  
  .post-container {
    width: 100%;
  }
  
  .post-details {
    margin-top: var(--spacing-2);
    border-top: 1px solid var(--color-border);
    padding-top: var(--spacing-1);
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .post-details .author-username {
    color: var(--color-branding);
    font-weight: bold;
  }
  
  .post-image-container {
    width: 100%;
    overflow: hidden;
    border-radius: var(--radius-2);
  }
  
  .post-image-container .post-image {
    width: 100%;
  }
  
  .post-title {
    margin: 0;
    margin-bottom: var(--spacinsubreddit-nameg-2);
    color: var(--color-text-header);
  }
  
  .post-votes-container {
    margin-right: var(--spacing-3);
  }
  
  .post-votes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-action {
    font-size: 1.5rem;
  }
  
  .post-votes-container .post-votes-value {
    margin: var(--spacing-1) 0;
    font-weight: bold;
  }
  
  .post-votes-value.up-vote {
    color: var(--color-success) !important;
  }
  
  .post-votes-value.down-vote {
    color: var(--color-alert) !important;
  }
  
  .up-vote:hover {
    color: var(--color-success) !important;
  }
  
  .up-vote.active {
    color: var(--color-success) !important;
  }
  
  .down-vote:hover {
    color: var(--color-alert) !important;
  }
  
  .down-vote.active {
    color: var(--color-alert) !important;
  }
  
  .icon-action-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary);
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: var(--radius);
  }
  
  .icon-action-button:hover {
    background: var(--color-on-hover);
  }
  
  .post-comments-container {
    display: flex;
    align-items: center;
  }
  
  .post-comments-container .icon-action {
    margin-right: var(--spacing-0);
  }
  
  .author-details {
    display: flex;
    align-items: center;
  }
  
  .showing-comments {
    color: var(--color-branding);
  }
  