@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

/* From template copied */ 

:root {
  --color-background: #fcfcfc;
  --color-background-secondary: #f3f3f3;
  --color-foreground: #ffffff;
  --color-border: #e6e6e6;
  --color-text-header: #444444;
  --color-text-body: #6f6f6f;
  --color-text-secondary: #717171;
  --color-text-invert: #ffffff;
  --color-text-link: #4ec3f5;
  --color-branding: #3d5af1;
  --color-branding-transparent: #3d5af140;

  --color-alert: #ff304f;
  --color-success: #45b81f;
  --color-on-hover: #0000000f;

  --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  --box-shadow-hover: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;

  --spacing-0: 4px;
  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 36px;
  --spacing-5: 64px;

  --radius: 4px;
  --radius-2: 8px;
}

html,
body {
  background: var(--color-background);
  color: var(--color-text-body);
}

p {
  margin: 0;
}


/* Original old stuff */ 

:root {
  --delay: 0s;
  --color-BgSide: #252954;
  --color-Bg: #0e0e23;
  --color-Primary1: #4255d4;
  --color-Secondary1: #141834;
  --color-Secondary2: #13162f;
  --color-Secondary3: #1a2049;
  --color-Secondary4: #1a1d3a;
  --color-Secondary5: #212752;
  --color-Secondary6: #11132c;
  --color-Secondary7: #1a1b3c;
  --color-Secondary8: #10122b;
  --color-Secondary9: #15193c;
  
  --color-Text1: #9b9ca7;
  --color-Text2: #e7e8ea;
  --color-TextSelected: #bebec4;
  --color-TextWait: #3E4EC2;
  --color-TextDisc: #17a98a;
  --color-TextDiscSub: #e85471;

  --color-TextActive: #1aa385;
  --color-Highlight: #d14b69;
  --color-CircleLine: #5b5f78;
  --color-CircleLineOut: #4356d6;
  --color-CircleText: #3e50c5;

}

* {
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  border-radius: 6px;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--color-BgSide);
  color: var(--color-Text1);
}


.interactive:hover {
  opacity: 70%;
  transform: scale(1.02);
}


.wrapper {
  max-width: 1600px;
  background-color: var(--color-Bg);
  display: flex;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
}

.left-side {
  width: 6rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 117px 0 40px;
  /*-webkit-animation: left .6s var(--delay) both;
          animation: left .6s var(--delay) both; */
}
.left-side svg {
  width: 24px;
  cursor: pointer;
}
.left-side svg + svg {
  margin-top: 34px;
}
.left-side svg:last-child {
  margin-top: auto;
}
.left-side svg.active, .left-side svg:hover {
  color: var(--color-Primary1);
}

@-webkit-keyframes left {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes left {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 25px;
  overflow: auto;
}

.header {
  background: var(--color-Secondary1);
  background: radial-gradient(circle, var(--color-Secondary1) 0%, var(--color-Secondary2) 100%);
  box-shadow: 0 16px 12px var(--color-Bg);
  width: 100%;
  padding: 0 30px;
  margin-top: 10px;
  -webkit-animation: top 1s both;
          animation: top 1s both;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}

/*.header:before {
  content: "";
  width: 100%;
  height: 25px;
  position: absolute;
  top: -25px;
  left: 0;
  background-color: var(--color-Bg);
}*/

.header-link {
  color: var(--color-Text1);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
}
.header-link svg {
  width: 20px;
  margin-right: 14px;
}
.header-link.active, .header-link:hover {
  background: var(--color-Secondary6);
  border-bottom: 3px solid var(--color-Primary1);
}

.logo {
  padding: 20px 50px 20px 0;
  font-size: 16px;
  color: var(--color-Text2);
}
.logo .fa-cat {
  margin-right: 10px;
}
.logo-det {
  background: var(--color-Primary1);
  padding: 8px;
  margin-left: -2px;
  border-radius: 50%;
  font-size: 15px;
}

.search {
  background-color: var(--color-Secondary3);
  color: var(--color-Text1);
  font-size: 15px;
  height: 35px;
  border-radius: 3px;
  margin: auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search input {
  background: none;
  outline: none;
  color: var(--color-Text1);
}

.icon-btn {
  border: none;
  font-size: 16px;
  cursor: pointer;
  background: none;
  outline: none;
  color: var(--color-Text1);
}

header .socialIcons {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  justify-content: end;
  width: 150px;
}


header .socialIcons a {
  font-size: 12px;
  position: relative;
  bottom: 1px;
  margin-left: 10px;
  color: var(--color-Primary1);
  color: var(--color-Text1);
}


.result-info {
  display: flex;
  align-items: center;
}
.result-info svg {
  width: 20px;
}
.result-info .profile {
  margin: 0 20px 0 12px;
  width: 18px;
}

.button {
  display: flex;
  align-items: center;
  color: var(--color-Text1);
  background: var(--color-Bg);
  border: none;
  padding: 5px 20px;
  border-radius: 4px;
  margin-right: 20px;
}
.button svg {
  margin-left: 10px;
  width: 16px;
}

[type="submit"], [type="reset"], button, html [type="button"] {
  -webkit-appearance: none;
}

.content-area {
  display: grid;
  grid-template-columns: auto 300px;           
  column-gap: 25px;
}


.error {

}

/* --- results ---*/
.result-box {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
.result-box + .result-box {
  margin-top: 20px;
}

.result-box + .result-box .today {
  margin-left: 30px;
}


.today {
  position: relative;
}
.today:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: var(--color-Highlight);
  bottom: -8px;
  right: 50%;
  border-radius: 50%;
}

.card {
  background: var(--color-Secondary3);
  background: radial-gradient(circle, var(--color-Secondary3) 0%, var(--color-Secondary2) 100%);
  padding: 40px 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.card .card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card .title {
  font-size: 16px;
  font-weight: 500;
}
.card .author {
  font-size: 13px;
  line-height: 1.6em;
  color: var(--color-Primary1);
}
.card .timestamp {
  font-size: 13px;
  line-height: 1.6em;
  color: var(--color-Primary1);
  min-width: 60px;
  text-align: right;
}
.loader {
  background: var(--color-Secondary3);
  background: radial-gradient(circle, var(--color-Secondary3) 0%, var(--color-Secondary2) 100%);
  padding: 40px 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  animation: top 1s var(--delay) both;
  --delay: .3s
}

.activity {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.activity .title {
  margin-bottom: 10px;
}
.activity-links {
  display: flex;
  align-items: center;
  margin-top: auto;
  font-size: 15px;
}
.activity-link {
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.activity-link + .activity-link {
  margin-left: 25px;
}
.activity-link + .activity-link:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: var(--color-Highlight);
  top: -2px;
  right: -8px;
  border-radius: 50%;
}
.activity-link + .activity-link:hover:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 2px;
  background: var(--color-Primary1);
  left: 0;
  bottom: 0;
}
.activity-link + .activity-link:hover {
  color: var(--color-TextSelected);
  -webkit-text-stroke: 0.3px;
}
.activity-link.active {
  color: var(--color-TextSelected);
  font-weight: 500;
}
.activity-link.active:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 2px;
  background: var(--color-Primary1);
  left: 0;
  bottom: 0;
}

.result-content {
  margin-left: 20px;
  flex: 1;
}
.up-down-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 30px;
}

.up-down-buttons i:hover {
  opacity: 70%;
  transform: scale(1.02);
}


.voteCount {
  margin: 10px 0 10px 0;
}


.main-image {
  display: flex;
  align-items: center;
  margin-top: auto;
  border-radius: 6px;
}
.result-image {
  margin-top: 20px;
  /*background: var(--color-Secondary4);
  background: linear-gradient(45deg, var(--color-Secondary4) 0%, var(--color-Secondary5) 100%); */
  width: 100%;
  border-radius: 6px;
}

.result-image-card {
  background: var(--color-Secondary4);
  background: linear-gradient(45deg, var(--color-Secondary4) 0%, var(--color-Secondary5) 100%);
  padding: 20px;
  width: 100%;
  border-radius: 6px;
}
.result-image-card + .result-image-card {
  margin-left: 20px;
  background: var(--color-Secondary4);
  background: linear-gradient(325deg, var(--color-Secondary4) 0%, var(--color-Secondary5) 100%);
}

.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.comment-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.comment-button .commentCount {
  margin-left: 5px;
}

.comment {
  background-color: var(--color-Secondary3);
  color: var(--color-Text1);
  font-size: 15px;
  border-radius: 6px;
  padding: 20px;
  margin-top: 10px;
}


.comment .card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comment .textSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment .text {
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}
.comment .author {
  font-size: 11px;
  line-height: 1.6em;
  color: var(--color-Primary1);
}
.comment .timestamp {
  font-size: 11px;
  line-height: 1.6em;
  color: var(--color-Primary1);
  min-width: 60px;
  text-align: right;
}


.profile-img {
  width: 46px;
  height: 46px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid var(--color-TextSelected);
  flex-shrink: 0;
}

.point {
  font-size: 14px;
  color: var(--color-TextSelected);
  font-weight: 500;
}

.sub-point {
  font-size: 13px;
  margin-top: 4px;
}

.discount {
  max-width: 320px;
  width: 100%;
}
.discount .title {
  margin-bottom: 30px;
}
.discount .subtitle {
  margin-bottom: 8px;
}
.discount .subtitle-count {
  font-size: 17px;
  color: var(--color-TextDisc);
  font-weight: 500;
}
.discount .subtitle-count + .subtitle {
  margin-top: 20px;
}
.discount .subtitle-count.dist {
  color: var(--color-TextDiscSub);
}
.discount-wrapper {
  display: flex;
}
.discount-chart {
  margin-left: auto;
}
.discount-profile {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.discount-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 16px 0 12px;
}
.discount-name {
  font-weight: 500;
  font-size: 15px;
}
.discount-type {
  font-size: 13px;
  margin-top: 4px;
}

.circle {
  width: 100px;
  height: 100px;
  border: 3px solid var(--color-CircleLine);
  border-radius: 50%;
  position: relative;
}

/*
.pie {
  position: relative;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.pie svg circle {
  fill: transparent;
  stroke: var(--color-CircleLineOut);
  stroke-width: 14;
  stroke-dasharray: 275;
  stroke-dashoffset: 235;
  -webkit-animation: pieChart 3.8s linear forwards;
          animation: pieChart 3.8s linear forwards;
}
*/
@-webkit-keyframes pieChart {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes pieChart {
  100% {
    stroke-dashoffset: 0;
  }
}
.counter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-CircleText);
  font-weight: 500;
}

.offer-button {
  background: var(--color-Primary1);
  padding: 14px;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  margin-right: 0;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}

.degree {
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #818394;
  font-weight: 500;
}
.degree svg {
  width: 24px;
  margin-right: 12px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  font-size: 13px;
  font-weight: 500;
}
.items.numbers {
  margin-top: 0;
}

.item {
  flex: 0 1 calc(100% / 7);
  padding: 10px 5px;
  text-align: center;
}
.item.is-active {
  background: var(--color-Primary1);
  border-radius: 50%;
  color: #fff;
}
.item.disable {
  color: #595b5b;
}

.time {
  height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #9e5924;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.time.is-wait {
  background: #4255d3;
}


@-webkit-keyframes turn {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes turn {
  100% {
    transform: rotate(1turn);
  }
}


@-webkit-keyframes fly {
  40% {
    transform: translate(-6px, -6px);
  }
  60% {
    transform: translate(-12px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes fly {
  40% {
    transform: translate(-6px, -6px);
  }
  60% {
    transform: translate(-12px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.Content-related {
  animation: top 1s var(--delay) both;
  --delay: .3s
}
.Content-related-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
.Content-related .is-wait {
  color: var(--color-TextWait);
}


.subreddit-wrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px;
}


.subreddit-wrapper .subreddit-logo {
  background-image:url(./image/paw.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  padding: 3px;
  margin-right: 12px;
  border: 1px solid var(--color-Primary1);
} 


.subreddit-wrapper path {
  width: 24px;
}
.subreddit-money {
  margin-left: auto;
}


.subreddit-name {
  color: var(--color-Text2);
  margin-bottom: 6px;
}

.selected-subreddit {
  color: var(--color-branding) !important;
  /*border-right: 5px solid var(--color-branding);*/
  background: var(--color-Bg);
  font-weight: 800;
}

.avatar-profile-image {
  border-radius: 50%;
  height: 20px;
  margin-right: var(--spacing-1);
}

@media screen and (max-width: 1500px) {
  .wrapper {
    max-width: 1200px;
  }

  .activity {
    max-width: none;
  }

  .discount {
    width: 48%;
    max-width: none;
    height: 100%;
  }

  .Content-related {
    margin-top: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .time {
    display: none;
  }
}
@media screen and (max-width: 1060px) {
  .result-info .button,
.result-info .hour {
    display: none;
  }
}

@media screen and (max-width: 930px) {

  .Content-related {
    width: 100%;
    margin-left: 0;
    height: 100%;
    margin-top: 20px;
  }

  .header-link {
    display: none;
  }

  .result-info .profile {
    margin-right: 0;
  }
}
@media screen and (max-width: 850px) {
  .activity-card,
.discount {
    width: 100%;
  }

  .result-box .discount {
    margin-left: 0;
    margin-top: 20px;
  }

  .left-side {
    display: none;
  }

  .main-container {
    padding-left: 25px;
  }
}

@media screen and (max-width: 420px) {
  .result-image-card + .result-image-card {
    display: none;
  }
}

/*
.result-box > * {
  -webkit-animation: top 1s var(--delay) both;
          animation: top 1s var(--delay) both;
}
*/


/*-----Skeleton Stuff-------*/

@keyframes react-loading-skeleton {
  100% {
      transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: var(--color-Secondary3);
  --highlight-color: var(--color-TextWait);
  --animation-duration: .75s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute; 
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
